// ShopifyBuyButton.js
import React, { useEffect, useRef } from 'react';

const ShopifyBuyButton = () => {
  const buyButtonRef = useRef(null);

  useEffect(() => {
    const scriptURL =
      'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

    const loadScript = () => {
      return new Promise((resolve) => {
        if (window.ShopifyBuy) {
          resolve();
        } else {
          const script = document.createElement('script');
          script.async = true;
          script.src = scriptURL;
          script.onload = resolve;
          document.body.appendChild(script);
        }
      });
    };

    loadScript().then(() => {
      if (window.ShopifyBuy && window.ShopifyBuy.UI) {
        const client = window.ShopifyBuy.buildClient({
          domain: 'q69v96-10.myshopify.com', // Your Shopify store domain
          storefrontAccessToken: 'bf6a220aab06861829011da85b9b7a96', // Your storefront access token
        });

        window.ShopifyBuy.UI.onReady(client).then((ui) => {
          ui.createComponent('product', {
            id: '10313779675467', // Your product ID
            node: buyButtonRef.current,
            moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
            options: {
              product: {
                styles: {
                  product: {
                    'max-width': '90%', // Adjust as needed
                    'margin-left': 'auto',
                    'margin-right': 'auto',
                    'margin-bottom': '20px',
                  },
                  img: {
                    'max-width': '100%',
                    'margin-left': 'auto',
                    'margin-right': 'auto',
                  },
                  title: {
                    'font-size': '16px',
                  },
                  price: {
                    'font-size': '14px',
                  },
                  button: {
                    ':hover': {
                      'background-color': '#000000',
                    },
                    'background-color': '#000000',
                    ':focus': {
                      'background-color': '#000000',
                    },
                  },
                  '@media (min-width: 601px)': {
                    product: {
                      'max-width': 'calc(25% - 20px)',
                      'margin-left': '20px',
                      'margin-bottom': '50px',
                    },
                    title: {
                      'font-size': '18px',
                    },
                    price: {
                      'font-size': '16px',
                    },
                  },
                },
                text: {
                  button: 'Ajouter au panier',
                },
                contents: {
                  quantityInput: true,
                },
              },
              cart: {
                styles: {
                  button: {
                    ':hover': {
                      'background-color': '#000000',
                    },
                    'background-color': '#000000',
                    ':focus': {
                      'background-color': '#000000',
                    },
                  },
                },
                text: {
                  title: 'Panier',
                  total: 'Sous-total',
                  empty: 'Votre panier est vide.',
                  notice: "Taxes et frais d'expédition calculés lors du paiement.",
                  button: 'Passer au paiement',
                },
              },
              toggle: {
                styles: {
                  toggle: {
                    'background-color': '#000000',
                    ':hover': {
                      'background-color': '#000000',
                    },
                    ':focus': {
                      'background-color': '#000000',
                    },
                  },
                },
              },
              // ... include other options if needed
            },
          });
        });
      }
    });
  }, []);

  return <div ref={buyButtonRef}></div>;
};

export default ShopifyBuyButton;