import React from "react";
import "../../styles/Home.css";
import logo from "../../assets/images/tal_logo.png";
import ShopifyBuyButton from "../../ShopifyBuyButton";

export default function Home() {
    return (
        <div className="home">
            <header className="header-home">
                <img src={logo} alt="logo travail argent laisse durden" width={200}/>
            </header>
            <div className="products">
                <ShopifyBuyButton />
            </div>
        </div>
    );
}
